<div class="rows col-xl-12">
    <h1>
        <mat-icon color="primary">lists</mat-icon> Historique
    </h1>
    <div class="rows">
        <div *ngFor="let data of combinedData">
            <div class="rows event" *ngIf="data?.category === 'Orders_Raccordement' || (data?.transitionId?.statusId !== null) ||(data?.statusId)">
                <div class="col-xl-2">
                    Commande n° : <b>{{command?.reference}}</b>
                </div>
                <div class="col-xl-6">
                    <div *ngIf="data.category === 'Orders_Raccordement'">
                        <i>
                            {{ translateActivity(data)}} 
                            <a *ngIf= "data?.content?.callback === 'EC_PREPARE_RDV_VT__EC_WAIT_RETAILER_RDV_VT'" href="order/rdvChoice/VT/{{data?.orderId}}" target="_blank">Lien vers les Rdvs VT porposés </a>
                            <a *ngIf= "data?.content?.callback === 'EC_PREPARE_RDV_MES__EC_WAIT_RETAILER_RDV_MES'" href="order/rdvChoice/MES/{{data?.orderId}}" target="_blank">Lien vers les Rdvs MES porposés </a>
                        </i>
                    </div>
                    <div *ngIf="data?.category !== 'Orders_Raccordement'">
                        <span *ngIf="data?.statusId">
                            <b>Changement d'état :</b> État forcée <span *ngIf="data?.previousStatusId">de {{data?.previousStatusId?.name}}</span> à : <b>{{data?.statusId?.name}}</b>
                        </span>
                        <span *ngIf="data?.transitionId?.statusId !== null ">
                            <b>Changement d'état :</b> {{ data?.transitionId?.statusId?.name }} -> <b>{{ data?.transitionId?.nextStatusId?.name }}</b>
                        </span>
                        <!-- Handle other categories as needed -->
                    </div>
                </div>
                <div class="col-xl-2">
                    {{ datepipe.transform(data?.date, 'dd/MM/yyyy HH:mm:ss')}}
                </div>
                <div class="col-xl-2">
                    {{data?.userId?.contactId?.firstName}} {{data?.userId?.contactId?.lastName}}
                </div>
            </div>
        </div>
    </div> 
</div>