import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommentService } from 'src/app/core/services/comment.service';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { OrderService } from 'src/app/core/services/order.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EscalationCancelConfirmDialgComponent } from './escalation-cancel-confirm-dialg/escalation-cancel-confirm-dialg.component';
import { EscalationService } from 'src/app/core/services/escalation.service';
import { AddFollowerModalComponent } from 'src/app/pages/private/order/components/followers/add-follower-modal/add-follower-modal.component';
import { FollowerService } from 'src/app/core/services/follower.service';
import { MailService } from 'src/app/core/services/mail.service';



@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  @Input() command: any;
  @Input() comments: any[] = [];
  @Input() mails: any[] = [];
  combinedItems: (any)[] = [];
  showMessage!: boolean;
  current_user!: any;
  commentForm!: FormGroup;
  text: string;
  isEscalated: boolean = false;
  escalationId!: string;
  escalations: any = [
    { id: 'DEADLINE_EXCEEDED', name: 'DÉPASSEMENT DU DÉLAI' },
    { id: 'TECHNICAL_PROBLEM', name: 'PROBLEME TECHNIQUE' },
    { id: 'ADMINISTRATIF_PROBLEM', name: 'PROBLEME ADMINISTRATIF' }
  ];
  escalationCause !: string;
  command_id: any;
  commentFilter?: string = 'all';
  currentEscalation!: any;
  currentFollowers: any[] = [];
  showMails!: boolean;

  constructor(
      public dialog: MatDialog,
      private commentService: CommentService, private tokenService: TokenStorageService, private orderService: OrderService,
      private escalationService: EscalationService, private followerService: FollowerService,private mailService: MailService,
      public datepipe: DatePipe, private modalDialog: MatDialog, private route: ActivatedRoute, private formBuilder: FormBuilder) {
    
        this.text = this.commentService.getMessageText();
    this.current_user = this.tokenService.getUser();
    this.commentForm = this.formBuilder.group({
      text: [''] // You can set default text if needed
    });

  };


  get textControl(): FormControl {
    return this.commentForm.get('text') as FormControl;
  };


  handleFormChanges() {
    this.textControl?.valueChanges.subscribe(newValue => {
      this.text = newValue;
    });
  };


  async ngOnInit() {
    this.route.params.subscribe(params => {
      this.command_id = params['id'] || this.command?._id;
    });

    const savedText = this.commentService.getMessageText();
    this.textControl.setValue(savedText);

    this.commentService.commentUpdated$.subscribe(() => {
      this.load();
    });
    this.load();

    let escalations = await this.orderService.getEscalations(this.command_id);
    this.currentEscalation = escalations.find((item: any) => item?.cause && item.dateEnd === null);

    if (this.currentEscalation) {
      this.escalationId = this.currentEscalation?._id;
      this.isEscalated = true;
      this.escalationCause = this.currentEscalation?.cause;
    }

    this.handleFormChanges();
    this.textControl.valueChanges.subscribe(newValue => {
      this.commentService.setMessageText(newValue);
    });

    this.showMails = false;
  };


  async addComment(text: string) {
    const formattedText = text.replace(/\n/g, '<br>');
    this.currentFollowers = await this.followerService.getFolowers(this.command_id);
    let comment = {
      message: formattedText,
      userId: this.current_user.userId,
      orderId: this.command._id,
      statusId: this.command.statusId._id,
      escalationId: this.escalationId,
      isEscalated: this.isEscalated,
      escalationCause: this.escalationCause,
      followersIds: this.currentFollowers.map(f => f.userId?._id)
    };

    if (comment.message) {
      this.commentService.addNewComment(comment).then(async () => {

        if (this.commentFilter === 'few') {
          this.comments = await this.commentService.getCommentsByStatus(this.command_id, this.command.statusId._id);
        }
        else {
          this.comments = await this.commentService.getCommentsByOrderId(this.command._id);
        }
        this.combinedItems = [...this.comments.map(comment => ({ ...comment, type: 'comment' })), ...this.mails.map(mail => ({ ...mail, type: 'mail' }))];
        this.combinedItems.sort((a: any, b: any) => {
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();
          return dateB - dateA;
        });
        this.orderService.triggerOrderUpdated();

      });
    }

    this.commentForm.reset();
    this.commentService.setMessageText('');
  };


  async OnChangeFilter(event: any) {
    if (this.commentFilter === 'few') {
      this.comments = await this.commentService.getCommentsByStatus(this.command_id, this.command?.statusId?._id);
      this.mails = await this.mailService.getMailsByStatus(this.command_id ,  this.command?.statusId?._id);
    }
    else if (this.commentFilter === 'mails') {
      this.mails = await this.mailService.getAllMails(this.command_id);
      this.comments = [];
    }
    else {
      this.mails = await this.mailService.getAllMails(this.command_id);
      this.comments = await this.commentService.getCommentsByOrderId(this.command._id);
    }
    this.combinedItems = [...this.comments.map(comment => ({ ...comment, type: 'comment' })), ...this.mails.map(mail => ({ ...mail, type: 'mail' }))];
    this.combinedItems.sort((a: any, b: any) => {
      const dateA = new Date(a.date).getTime();
      const dateB = new Date(b.date).getTime();
      return dateB - dateA;
    });
  };


  descativateEscalation() {
    const dialogRef = this.modalDialog.open(EscalationCancelConfirmDialgComponent, {
      disableClose: false,
      data: {
        escalation: this.currentEscalation,
        order: this.command,
      },
      width: '40%',
      height: '30%',
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      this.currentEscalation = await this.escalationService.getEscalation(this.currentEscalation?._id);

      if (this.currentEscalation) {
        this.escalationId = this.currentEscalation?._id;
        this.isEscalated = this.currentEscalation?.dateEnd ? false : true;
        this.escalationCause = this.currentEscalation?.cause;
      }

      this.commentService.triggerCommentUpdated();
    });
  };


  onChangeEscalationCause(event: any) {
    this.commentService.setEscalationCause(event);
  };


  translateCause(name: string) {
    let cause = this.escalations.find((item: any) => item.id === name);
    return cause?.name;
  };


  async load() {
    this.orderService.getOrder(this.command_id).then(async (response: any) => {
      
      if (this.commentFilter === 'few') {
        this.comments = await this.commentService.getCommentsByStatus(this.command_id, response.statusId?._id);
        this.mails = await this.mailService.getMailsByStatus(this.command_id ,  response.statusId?._id);
      }
      else {
        this.comments = await this.commentService.getCommentsByOrderId(this.command_id);
        this.mails = await this.mailService.getAllMails(this.command_id);
        
      }
      if (this.mails  && this.comments) {
        this.combinedItems = [...this.comments.map(comment => ({ ...comment, type: 'comment' })), ...this.mails.map(mail => ({ ...mail, type: 'mail' }))];
        this.combinedItems.sort((a: any, b: any) => {
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();
          return dateB - dateA;
        });
      }
    });

    
    this.isEscalated = false;
  };


  OpenAddFollowereModal() {
    const dialogRef = this.dialog.open(AddFollowerModalComponent, {
      data: {
        order: this.command
      },
      width: '60%',
      height: '60%',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.followerService.triggerFollowerUpdated();
    });
  };

  toggleMessage(): void {
    this.showMessage = this.mailService.toggleMessage(this.showMessage);
  };
};