<div class="documentComponent">
    <mat-label><b>{{translateDocumentType(documentType)}}</b></mat-label>
    <div class="card box" [ngClass]="{empty: !documents[0]}">
        <div class="file-header">
            <div class="file-status" *ngIf="documents[0]">
                <span>
                    <mat-icon matTooltip="En attente de validation" class="text-primary"
                        *ngIf="documents[0].status === 'WAITING_VALIDATION'">pending</mat-icon>
                </span>
                <span>
                    <mat-icon matTooltip="Document Validé" class="text-success"
                        *ngIf="documents[0].status === 'ACCEPTED'">done</mat-icon>
                </span>
                <span>
                    <mat-icon matTooltip="Rejeté" class="text-danger" *ngIf="documents[0].status === 'REJECTED'">report
                    </mat-icon>
                </span>
            </div>
            <div class="file-options" >
                <mat-icon matTooltip="Traiter le fichier" (click)="openFileValidationForm(documents[0])" color="primary"
                    *ngIf="documents[0] && hasPermission && hasRole && isActive">settings</mat-icon>
                <mat-icon matTooltip="Importer un nouveau fichier" *ngIf="hasPermission && hasRole && isActive"
                    (click)="openFileImportModal(documentType!.slice(3))" color="primary">add</mat-icon>
                <mat-icon matTooltip="Télécharger le fichier" (click)="downloadDocument(documents[0])" color='primary'
                    *ngIf="documents[0]">download</mat-icon>
                <mat-icon matTooltip="Visionner le document" (click)="openPdfViewer(documents[0])" color='primary'
                    *ngIf="documents[0]">visibility</mat-icon>
            </div>
            <input #fileInput type="file" style="display: none" (change)="handleFileInputChange($event)" />
        </div>
        <div class="file-content" *ngIf="documents[0]">
            <span matTooltip="{{documents[0]?.name}}"><b>V{{documents.length}}:</b> {{
                documents[0]?.name }}</span>
            <span class="file-date"> {{ datepipe.transform(documents[0].date, 'dd/MM/yyyy HH:mm') }}</span>
        </div>
        <mat-accordion>
            <mat-expansion-panel (opened)="true" *ngIf="documents.length > 1">
                <mat-expansion-panel-header>
                    <mat-panel-title>Historique</mat-panel-title>
                    <mat-panel-description>
                        <mat-icon>archive</mat-icon>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div *ngFor="let document of documents; let i = index">
                    <div class="file-header" *ngIf="i >= 1">
                        <div class="file-status">
                            <span>
                                <mat-icon matTooltip="En attente de validation" class="text-primary"
                                    *ngIf="document.status === 'WAITING_VALIDATION'">pending</mat-icon>
                            </span>
                            <span>
                                <mat-icon matTooltip="Document Validé" class="text-success"
                                    *ngIf="document.status === 'ACCEPTED'">done</mat-icon>
                            </span>
                            <span>
                                <mat-icon matTooltip="Rejeté" class="text-danger"
                                    *ngIf="document.status === 'REJECTED'">report</mat-icon>
                            </span>
                            <span matTooltip="{{document?.name}}"><b>V{{i}}:</b> {{
                                document?.name }}
                            </span>
                        </div>
                        <div class="file-options" *ngIf="hasPermission">
                            <mat-icon matTooltip="Télécharger le fichier" (click)="downloadDocument(document)"
                                color='primary' *ngIf="document">download</mat-icon>
                        </div>
                        <br>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>