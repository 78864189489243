import { Injectable } from '@angular/core';
import { CanActivateChild, Router } from '@angular/router';


import { TokenStorageService } from 'src/app/core/services/token-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivateChild {
    constructor(
        private tokenService: TokenStorageService,
        private router: Router
    ) { }
    canActivateChild(): boolean {
        let current_user = this.tokenService.getUser();
        let isAdmin = current_user.role[0].includes('SUPER_ADMIN');
        if (!isAdmin) {
            this.router.navigate(['/']); 
            return false;
        }
        return true;
    }
}