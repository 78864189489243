import { Injectable, ComponentFactoryResolver, Injector, ApplicationRef, ComponentRef, ViewContainerRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import axios from 'axios'; 
const TANSITTIONS_API = environment.back_url + '/transitions/';
const config = { withCredentials: true}
import { AssignAgencyComponent } from '../../pages/private/order/components/general-infos/assignment/assign-agency/assign-agency.component';
import { AssignEngineeringOfficerComponent } from '../../pages/private/order/components/general-infos/assignment/assign-engineering-officer/assign-engineering-officer.component';
import { AssignProviderComponent } from '../../pages/private/order/components/general-infos/assignment/assign-provider/assign-provider.component';
import { AssignSupervisorComponent } from '../../pages/private/order/components/general-infos/assignment/assign-supervisor/assign-supervisor.component';
import { FciRefComponent } from '../../pages/private/order/components/general-infos/dt/fci-ref/fci-ref.component';
import { RdvDateMesComponent } from '../../pages/private/order/components/general-infos/rt/rdv-date-mes/rdv-date-mes.component';
import { RtFailureReasonComponent } from '../../pages/private/order/components/general-infos/rt/rt-failure-reason/rt-failure-reason.component';
import { StateComponent } from '../../pages/private/order/components/general-infos/rt/state/state.component';
import { FailureReasonComponent } from '../../pages/private/order/components/general-infos/vt/failure-reason/failure-reason.component';
import { RdvDateComponent } from '../../pages/private/order/components/general-infos/vt/rdv-date/rdv-date.component';
import { RdvStatusComponent } from '../../pages/private/order/components/general-infos/vt/rdv-status/rdv-status.component';
import { RdvStatusMesComponent } from '../../pages/private/order/components/general-infos/rt/rdv-status-mes/rdv-status-mes.component';
import { RdvDescriptionComponent } from '../../pages/private/order/components/general-infos/vt/rdv-description/rdv-description.component';
import { DocumentManagerComponent } from 'src/app/pages/private/order/components/general-infos/document-manager/document-manager.component';

@Injectable({
  providedIn: 'root',
})
export class ComponentCallerService {
  private componentMapping: Record<string, any> = {
    DT_PLAN_PDF:                  DocumentManagerComponent,
    DT_PLAN_ZIP:                  DocumentManagerComponent,
    DT_OTHER_BE:                  DocumentManagerComponent,
    DT_DOE:                       DocumentManagerComponent,
    DT_DFT:                       DocumentManagerComponent,
    DT_FCI_REF:                   FciRefComponent,
    VT_RDV_STATUS:                RdvStatusComponent,
    VT_RDV_DATE:                  RdvDateComponent,
    VT_FAILURE_REASON:            FailureReasonComponent,
    VT_DTI_VT:                    DocumentManagerComponent,
    VT_FOA:                       DocumentManagerComponent,
    VT_RDV_DESRIPTION:            RdvDescriptionComponent,
    VT_OTHER_VT:                  DocumentManagerComponent,
    DF_QUOTATION:                 DocumentManagerComponent,
    DF_PURCHASE_ORDER:            DocumentManagerComponent,
    DF_BDR:                       DocumentManagerComponent,
    RT_STATE:                     StateComponent,
    RT_RDV_STATE:                 RdvStatusMesComponent,
    RT_RDV_DATE:                  RdvDateMesComponent,
    RT_MEASURES:                  DocumentManagerComponent,
    RT_AC_REQUEST:                DocumentManagerComponent,
    RT_AC_RESPONSE:               DocumentManagerComponent,
    RT_FAILURE_REASON:            RtFailureReasonComponent,
    ASSIGN_AGENCY:                AssignAgencyComponent,
    ASSIGN_SUPERVISOR:            AssignSupervisorComponent,
    ASSIGN_EngineeringOfficer:    AssignEngineeringOfficerComponent,
    ASSIGN_PROVIDER:              AssignProviderComponent
  };

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef
  ) {}

  callComponentAsync(fieldName: string, container: ViewContainerRef, command: any): Promise<void> {
    return new Promise<void>((resolve) => {
      const componentType = this.componentMapping[fieldName];
      if (componentType) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        const componentRef: ComponentRef<any> = componentFactory.create(this.injector);
        container.clear();
        if (componentType === DocumentManagerComponent) {
          // Set the command and documentType for DocumentManagerComponent
          componentRef.instance.command = command;
          componentRef.instance.documentType = fieldName; // Assuming documentType is the same as fieldName
        } else {
          // Set the command for other components
          componentRef.instance.command = command;
        }
        this.appRef.attachView(componentRef.hostView);

        container.insert(componentRef.hostView);

        componentRef.onDestroy(() => {
          this.appRef.detachView(componentRef.hostView);
        });
        resolve();
      }
    });
  }

  getComponent(reference: string): any{
    const component = this.componentMapping[reference];
    return { reference, component };
  }
  

  async getComponentsByReference(reference: string){
    return (await axios.post(TANSITTIONS_API + 'fields' , {reference: reference} , config)).data.data
  }


}
