import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthentificationService } from 'src/app/core/services/authentification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
  constructor(
    private authService: AuthentificationService,
    private router: Router
  ) {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.getLogin().pipe(
      map((login) => {
        if (login.data) {
          return login.data.loggedIn;
        }
        this.router.navigate(['/login']); 
        return false;
      })
    );
  }
}
