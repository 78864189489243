import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// guards
import { AuthGuard } from './core/guards/auth.guard';
import { AdminGuard } from './core/guards/admin.guard';

// pages public principales sans sous-pages
import { LoginComponent } from './pages/public/login/login.component';
import { NotAvailablePageComponent } from './pages/public/not-available-page/not-available-page.component';
import { RdvChoiceComponent }       from './pages/public/order/rdvChoice/rdv-choice.component';

//page principale avec sous-pages
import { DefaultComponent } from './pages/private/default.component';
// sous-pages d'administration
import { AdministrationComponent }  from './pages/private/admin/administration.component';
import { UsersComponent }           from './pages/private/admin/users/users.component';
import { NewuserComponent }         from './pages/private/admin/users/new/newuser.component';
import { UserComponent }            from './pages/private/admin/users/show/user.component';
import { EditUserComponent }        from './pages/private/admin/users/edit/edit-user.component';
import { NewentrepriseComponent }   from './pages/private/admin/entreprises/new/newentreprise.component';
import { EntreprisesComponent }     from './pages/private/admin/entreprises/entreprises.component';
import { NewAgencyComponent }       from './pages/private/admin/agencies/new/new-agency.component';
import { NewRoleComponent }         from './pages/private/admin/roles/new/new-role.component';
// autres sous-page
import { RdvCalendarComponent }             from './pages/private/calendar/rdv-calendar.component';
import { DashboardComponent }               from './pages/private/dashboard/dashboard.component';
import { NewCommandComponent }              from './pages/private/orders/new/newtreatment.component';
import { CommandTableComponent }            from './pages/private/orders/command-table.component';
import { CommandContainerComponent }        from './pages/private/order/command-container.component';
import { OrderRedirectComponentComponent }  from './pages/private/search/order-redirect-component.component';
import { EditProfileComponent }             from './pages/private/user/edit_profile/edit-profile.component';


const routes: Routes = [
  
    // public routes
  { path: 'order/rdvChoice/:type/:id', component: RdvChoiceComponent },
  { path: 'notFound', component: NotAvailablePageComponent },
  { path: 'login', component: LoginComponent },
  {
    // private route 
    path: '',
    component: DefaultComponent,
    canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo:'/dashboard', pathMatch:'full' },
      { path: 'dashboard', component: DashboardComponent},
      { path: 'orders',
        children: [
          { path: '', component: CommandTableComponent },
          { path: 'new', component: NewCommandComponent},
        ]
      },
      { path: 'search',
        children: [
          { path: 'mae/:reference', component: OrderRedirectComponentComponent },
          { path: 'ema/:reference', component: OrderRedirectComponentComponent },
        ]
      },
      { path: 'order/:id', component: CommandContainerComponent},
      { path: 'calendar', component: RdvCalendarComponent },
      { path: 'admin',
        canActivateChild: [AdminGuard],
        children: [
          { path: '', component: AdministrationComponent, },
          { path: 'users',
            children: [
              { path: '', component: UsersComponent},
              { path: 'new', component: NewuserComponent},
              { path: 'show/:id', component: UserComponent},
              { path: 'edit/:id', component: EditUserComponent},
            ]
          },
          { path: 'entreprises', component: EntreprisesComponent},
          { path: 'entreprises/new', component: NewentrepriseComponent},
          { path: 'agencies/new', component: NewAgencyComponent},
          { path: 'roles/new', component: NewRoleComponent},
        ]
      },
      { path: 'user/edit_profile/:id', component: EditProfileComponent},
      { path: '**', component:NotAvailablePageComponent }
    ]
  },
  { path: '**', redirectTo:'/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
